
import React, { useEffect, useState } from "react";
// import EvercommLogo from '../Images/Logos/evercomm-logo.png'
import EvercommLogo from '../Images/Logos/Evercomm-Logo_white-bg1.png'
import EvercommLogoWhite from '../Images/Logos/Evercomm-Logo_white.png'
import FA_BCorp  from '../Images/Logos/FA_B Corp-Logo.png'
import FA_COP28 from '../Images/Logos/FA_COP28-Logo.png'
import FA_SGC from '../Images/Logos/FA_SGC-Logo.png'
import unresonable_logo from "../Images/Logos/unreasonable-seal-black.png"
import Holo from "../Images/Logos/Holon.png"



import '../../App.css'
import LanguageSelector from "./languageSelector";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const [pathName, setPathName] = useState('');
  const [esSolunWidth, setEsSolunWdith] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const { t, i18n } = useTranslation("menu");

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.pageYOffset);
    }
    window.addEventListener("scroll", updatePosition);
    updatePosition();
    return () => window.removeEventListener("scroll", updatePosition);
  }, []);

  useEffect(() => {
    let lastScrollTop = 0;
    const navbar = document.querySelector('.nav');
    const navbarHeight = navbar.offsetHeight;

    const handleScroll = () => {
      const currentScrollTop = window.scrollY || document.documentElement.scrollTop;
      if (currentScrollTop > lastScrollTop) {
        if (currentScrollTop <=  0 && lastScrollTop <= 0){
          navbar.style.display = "block"

        } else {
          navbar.style.display = "none"

        }
      } else {
        navbar.style.display = "block"
      }
      lastScrollTop = currentScrollTop;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); 

  useEffect(() => {
    setPathName(window.location.pathname)
  }, [window.location.pathname]);

  useEffect(() => {
    console.log(i18n.language)
    const temp_width = 
      ((i18n.language === 'en' || i18n.language === 'en-US') ? 156.92 : 112)
    setEsSolunWdith(temp_width)

  }, [i18n.language])

  return (
    <div className="nav">
    <nav className="navbar navbar-expand-lg fixed-top d-flex justify-content-center py-3" style={{ background:  "#fff", fontWeight: 'bold', borderBottom: "4px solid #ffc107", minHeight: "86.45px"}}>
      <div className="container-fluid mx-lg-2 mx-md-3 mx-sm-2 mx-2 px-lg-2 px-md-2 px-sm-2 px-2 main_nav">
        < div className="d-flex justify-content-start">
        <a className="navbar-brand" href="/home">
          {/* {
            scrollPosition === 0 && window.location.pathname.includes("enterprise-solutions") ?
              <img className="evercomm_logo" src={EvercommLogoWhite} alt="evercomm_logo" style={{ width: 200 }} />
              : */}
              <img className="evercomm_logo py-auto" src={EvercommLogo} alt="evercomm_logo" style={{ width: 190 }} />
          {/* } */}

        </a>
        <span style={{borderLeft: "1px solid #002C96"}}></span>
        <a className=" my-auto FA_BCorp_logo" href="https://www.bcorporation.net/en-us/find-a-b-corp/company/evercomm/" target={"_blank"}>
          <img className="nav_logo" src={FA_BCorp} alt="FA_BCorp_logo" style={{ height: 50, padding: 0,  }} />
        </a>
        <a className=" my-auto FA_BCorp_logo">
          <img className="nav_logo" src={Holo} alt="Holo" style={{ height: 50, padding: 0,  }} />
        </a>
        <a className="my-auto FA_COP28_logo" href="https://www.evercomm.com.sg/2023/12/evercomm-awarded-unfcc-cop28-uae-techsprint-award" target={"_blank"} >
          <img className="nav_logo" src={FA_COP28} alt="FA_COP28_logo" style={{ height: 50, padding: 0 }} />
        </a>
        <a className="my-auto FA_SGC_logo" href="https://news.microsoft.com/en-sg/2023/06/28/singapore-greentech-challenge-2023-winners-set-to-accelerate-development-and-global-adoption-of-innovative-sustainability-solutions/" target={"_blank"}>
          <img className="nav_logo" src={FA_SGC} alt="FA_SGC_logo" style={{ height: 50, padding: 0 }} />
        </a>
            <a className="my-auto unresonable_logo_nav" title="Evercomm Singapore – an Unreasonable company" target="_blank" rel="noopener" href="https://unreasonablegroup.com/c/?id=6b2b16ad-2a57-4846-9cc6-5498ce21fd1c">
              <img className="nav_logo_unreachable" alt="Official Unreasonable Company" width="100" height="50" src={unresonable_logo} />
            </a>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => {
            const backdrop_ele = document.getElementsByClassName('component-container')[0];
            const body_ele = document.getElementsByClassName('body')[0]
            if (backdrop_ele.classList.contains("open-toggle")) {
              backdrop_ele.classList.remove("open-toggle")
              body_ele.classList.remove("open-toggle")
            } else {
              backdrop_ele.classList.add("open-toggle")
              body_ele.classList.add("open-toggle")
            }
          }}
        >
          <span className="navbar-toggler-icon" style={{ width: '30px', color: '#007bff' }} />
        </button>
        <div className="collapse navbar-collapse justify-content-end" id="navbarCollapse">
          <ul className="navbar-nav px-1">
            <li className="nav-item">
              <a className="nav-link px-1 py-2" href="/home">
                <span
                  className="px-2"
                  style={{ paddingBottom: 2, borderRadius: 30, background: pathName === '/home' ? '#002C96' : null, color: pathName === '/home' ? "#fff" : '#002C96' }}>
                  {t("Home")}
                </span>
              </a>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link px-1 py-2"
                href="/evercomm10"
              >
                <span
                  className="px-2"
                  style={{ paddingBottom: 2, borderRadius: 30, background: pathName === '/evercomm10' ? '#002C96' : null, color: pathName === '/evercomm10' ? "#fff" : '#002C96' }}>
                  {t('Evercomm10')}
                </span>
              </a>
           
            </li> */}
            <li className="nav-item enterprise-solution">
              <div className="nav-link px-1 py-2 dropdown">
                <span
                  className="px-2 esTab"
                  // data-bs-toggle="dropdown"
                  style={{ paddingBottom: 2, borderRadius: 30, background: pathName.includes('/enterprise-solutions') ? '#002C96' : null, }}>
                  <a
                    href="/enterprise-solutions"
                    style={{ color: pathName.includes('/enterprise-solutions') ? "#fff" : '#002C96', textDecoration: 'none' }}
                  >{t('Enterprise Solutions')}
                  </a>
                </span>
                <ul className="dropdown-menu mx-auto" aria-labelledby="dropdownMenuButton1" style={{ background: 'transparent', border: 'none', minWidth: esSolunWidth || document.getElementsByClassName('esTab')[0]?.offsetWidth, maxWidth: esSolunWidth || document.getElementsByClassName('esTab')[0]?.offsetWidth }}>
                  <li className="mb-1">
                    <a
                      className="dropdown-item py-0 px-2"
                      href="/enterprise-solutions/NXMap"
                      style={{ borderRadius: 30, background: pathName === '/enterprise-solutions/NXMap' ? '#002c96' : null, color: pathName === '/enterprise-solutions/NXMap' ? '#fff' : '#002C96' }}
                    >
                      {t("NXMap")}
                    </a>
                  </li>
                  <li className="mb-1">
                    <a
                      className="dropdown-item py-0 px-2"
                      href="/enterprise-solutions/NXOps"
                      style={{ borderRadius: 30, background: pathName === '/enterprise-solutions/NXOps' ? '#002c96' : null, color: pathName === '/enterprise-solutions/NXOps' ? '#fff' : '#002C96' }}
                    >
                      {t("NXOps")}
                    </a>
                  </li>
                  {/* <li className="mb-1">
                    <a
                      className="dropdown-item py-0 px-2"
                      href="/enterprise-solutions/NXWorld"
                      style={{ borderRadius: 30, background: pathName === '/enterprise-solutions/NXWorld' ? '#002c96' : null, color: pathName === '/enterprise-solutions/NXWorld' ? '#fff' : '#002C96' }}
                    >
                      {t("NXWorld")}
                    </a>
                  </li> */}
                  <li className="mb-1">
                    <a
                      className="dropdown-item py-0 px-2"
                      href="/enterprise-solutions/NXPlan"
                      style={{ borderRadius: 30, background: pathName === '/enterprise-solutions/NXPlan' ? '#002c96' : null, color: pathName === '/enterprise-solutions/NXPlan' ? '#fff' : '#002C96' }}
                    >
                      {t("NXPlan")}
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          
            <li className="nav-item">
              <a className="nav-link px-1 py-2"
                href="/news"
              >
                <span
                  className="px-2"
                  style={{ paddingBottom: 2, borderRadius: 30, background: pathName === '/news' ? '#002C96' : null, color: pathName === '/news' ? "#fff" : '#002C96' }}>
                  {t('News')}
                </span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link px-1 py-2"
                href="/about"
              >
                <span
                  className="px-2"
                  style={{ paddingBottom: 2, borderRadius: 30, background: pathName === '/about' ? '#002C96' : null, color: pathName === '/about' ? "#fff" : '#002C96' }}>
                  {t('About')}
                </span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link px-1 py-2" href="/contact">
                <span
                  className="px-2"
                  style={{ paddingBottom: 2, borderRadius: 30, background: pathName === '/contact' ? '#002C96' : null, color: pathName === '/contact' ? "#fff" : '#002C96' }}>
                  {t("Contact")}
                </span>
              </a>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link px-1 py-2 " href="https://www.facebook.com/evercommsg" target={'_blank'}>
                <span
                  className="text-white fw-bold"
                  style={{ width: 24, height: 24, display: 'inline-block', background: '#002c96', borderRadius: '50%' }}>
                  f
                </span>
              </a>
            </li> */}
              <li className="nav-item">
              <a className="nav-link px-1 py-2"
                href="https://nxmap.evercomm.io/login"
                target="blank"
              >
                <span
                  className="px-2"
                  style={{ paddingBottom: 2, borderRadius: 30, background: pathName === '/login' ? '#002C96' : null, color: pathName === '/login' ? "#fff" : '#002C96' }}>
                  {t('Login')}
                </span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link px-1 py-2" href="https://www.linkedin.com/company/evercomm-uni-tech-singapore/" target={'_blank'}>
                <span
                  className="text-white fw-bold"
                  style={{ width: 24, height: 24, display: 'inline-block', background: '#002c96', borderRadius: '50%' }}>
                  in
                </span>
              </a>
            </li>
            {/* <li className="nav-item d-flex align-items-center"> <LanguageSelector /></li> */}
          </ul>

        </div>
      </div>
    </nav>
    </div>
  )
};
export default Navbar;